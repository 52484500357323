<template>
  <div class="flex-column circleSearch_main">
    <pageHead
      class="messageMainView_head"
      :title="type === 0 ? '全部成员搜索' : '禁言成员搜索'"
    />
    <div class="search_box">
      <circleSearchs
        :placeholder="type === 0 ? '全部成员搜索' : '禁言成员搜索'"
        @click="handleClick"
        @input="handleInput"
        :disabled="false"
      ></circleSearchs>
      <span class="cancel_title" @click.prevent="handleCancel()">取消</span>
    </div>
    <div
      class="list-wrap"
      v-infinite-scroll="loadMore"
      infinite-scroll-throttle-delay="500"
      infinite-scroll-distance="50"
    >
      <div v-if="type === 0">
        <circle-manage-peson-list
          :member-list="data"
          @reload="reloadMemberList"
        />
        <no-more
          v-if="listQueryParams.hasNextPage === false && data.length > 0"
        />
        <van-empty
          v-if="listQueryParams.hasNextPage === false && data.length === 0"
          description="无成员加入"
        />
        <van-empty
          image="search"
          v-if="listQueryParams.hasNextPage === true && data.length === 0"
          description="请输入关键字搜索"
        />
      </div>
      <div v-else>
        <muted-list :muted-list="data" @reload="reloadMemberList" />
        <no-more
          v-if="listQueryParams.hasNextPage === false && data.length > 0"
        />
        <van-empty
          v-if="listQueryParams.hasNextPage === false && data.length === 0"
          description="无禁言成员"
        />
        <van-empty
          image="search"
          v-if="listQueryParams.hasNextPage === true && data.length === 0"
          description="请输入关键字搜索"
        />
      </div>
    </div>
  </div>
</template>

<script>
import circleSearchs from "@/components/searchBox";
import pageHead from "@/components/pageHead";
import {
  getMemberIdentity,
  listCircleMember,
  listMutedMember,
} from "@/api/circle";
import CircleManagePesonList from "@/views/app/circle/circleManagePerson/circleManagePesonList";
import MutedList from "@/views/app/circle/circleManagePerson/mutedList";

export default {
  name: "circlePersonSearch",
  data() {
    return {
      type: this.$route.query.type,
      data: [],
      userMemberIdentity: null,
      listQueryParams: {
        circleId: this.$route.query.circleId,
        pageNum: 1,
        pageSize: 10,
        hasNextPage: true,
      },
    };
  },
  components: {
    MutedList,
    CircleManagePesonList,
    circleSearchs,
    pageHead,
  },
  methods: {
    loadMore() {
      this.getMemberIdentity().then(() => {
        if (this.$route.query.type + '' === '0') {
          this.loadList();
        } else {
          this.loadMuteList();
        }
      });
    },
    // 加载啊用户信息
    getMemberIdentity() {
      return new Promise((resolve) => {
        if (this.userMemberIdentity !== null) {
          resolve(this.userMemberIdentity);
          return;
        }
        getMemberIdentity({circleId: this.$route.query.circleId})
          .then((res) => {
            this.userMemberIdentity = res.identity;
            resolve();
          })
          .catch(() => {
            this.userMemberIdentity = 3;
            resolve();
          });
      });
    },
    // 加载列表
    loadList() {
      let listQueryParams = this.listQueryParams;
      if (listQueryParams.hasNextPage) {
        listCircleMember(this.listQueryParams).then((res) => {
          listQueryParams.hasNextPage = res.current < res.pages;
          listQueryParams.pageNum = listQueryParams.pageNum + 1;
          let resData = this.data;
          res.records.forEach((item) => {
            let count = resData.filter(
              (innerItem) =>
                innerItem.userBasicVO.userId === item.userBasicVO.userId
            );
            if (count.length === 0) {
              resData.push(item);
            }
          });
          this.memberList = resData;
        });
      }
    },
    // 加载列表
    loadMuteList() {
      let listQueryParams = this.listQueryParams;
      if (listQueryParams.hasNextPage) {
        listMutedMember(this.listQueryParams).then((res) => {
          listQueryParams.hasNextPage = res.current < res.pages;
          listQueryParams.pageNum = listQueryParams.pageNum + 1;
          let resData = this.data;
          res.records.forEach((item) => {
            let count = resData.filter(
              (innerItem) =>
                innerItem.userBasicVO.userId === item.userBasicVO.userId
            );
            if (count.length === 0) {
              resData.push(item);
            }
          });
          this.data = resData;
        });
      }
    },
    handleInput(val) {
      if (val.trim()) {
        this.data = [];
        this.listQueryParams.username = val;
        this.listQueryParams.hasNextPage = true;
        this.listQueryParams.pageNum = 1;
        this.loadMore();
      }
    },
    reloadMemberList() {
      this.data = [];
      this.listQueryParams.hasNextPage = true;
      this.listQueryParams.pageNum = 1;
      this.listQueryParams.hasNextPage = true;
      this.loadMore();
    },
    handleClick(val) {
      this.handleInput(val);
    },
    handleCancel() {
      this.$router.back();
    },
  },
};
</script>

<style lang="scss" scoped>
.circleSearch_main {
  overflow: hidden;
  height: 100%;
  background: #fff;

  .list-wrap {
    flex: 1;
    overflow-y: auto;
    padding-bottom: 110px;
  }

  .search_box {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 12px;

    ::v-deep .van-search {
      width: 302px;
      padding-left: 15px;
      padding-right: 0;
    }
  }

  ::v-deep {
    .van-search__content {
      border-radius: 70px;
    }
  }

  .cancel_title {
    padding: 4px 15px;
    font-size: 14px;
    text-align: center;
    color: #000000;
  }

  .cont_list {
    padding: 0 15px;
    height: calc(100vh - 110px);
    overflow: scroll;
  }
}
</style>
